body,
html {
  height: 100vh;
  margin: 0;
  overflow: hidden;
  font-family: "TTSupermolot-BlackItalic";
  text-transform: uppercase;
  background-color: #592B8C;
}

#root {
  height: 100%;
}

@font-face {
  font-family: "SuzukiFunt-Heavy";
  src: url("./assets/fonts/SuzukiFuntv0.916-HeavyFront.otf");
}

@font-face {
  font-family: "SuzukiFunt-HeavyShadow";
  src: url("./assets/fonts/SuzukiFuntv0.916-HeavyShadow.otf");
}

@font-face {
  font-family: "TTSupermolot-BlackItalic";
  src: url("./assets/fonts/TypeType-TTSupermolot-BlackItalic.otf");
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url("./assets/fonts/OpenSans-Regular.ttf");
}
